$(document).ready(function () {
  let position = 0;
  let slidesToShow = 3;
  const widthReviews = $(".reviews").width();
  let slidesToScroll = 3;

  if (widthReviews <= 1150) {
    slidesToShow = 2;
    slidesToScroll = 2;
  }

  if (widthReviews <= 640) {
    slidesToShow = 1;
    slidesToScroll = 1;
  }

  const container = $(".slider-container");
  const track = $(".slider-track");
  const item = $(".slider-item");
  const btnPrev = $(".btn-prev");
  const btnNext = $(".btn-next");
  const itemsCount = item.length;
  let itemWidth = container.width() / slidesToShow;
  // if (slidesToShow === 1) {
  //   itemWidth = container.width() / 1.5;
  // }
  const movePosition = slidesToScroll * itemWidth;

  item.each((index, item) => {
    $(item).css({
      minWidth: itemWidth,
    });
  });

  btnPrev.click(() => {
    position += movePosition;
    setPosition(position);
    checkBtn();
  });

  btnNext.click(() => {
    position -= movePosition;
    setPosition(position);
    checkBtn();
  });

  // item.each((index, item) => {
  //   $(item).on("swiperight", () => {
  //     position += movePosition;
  //     setPosition(position);
  //     checkBtn();
  //   });
  // });

  const setPosition = (position) => {
    track.css({
      transform: `translateX(${position}px)`,
    });
  };

  const checkBtn = () => {
    btnPrev.prop("disabled", position === 0);
    btnNext.prop(
      "disabled",
      position <= -((itemsCount - slidesToShow) * itemWidth)
    );
  };

  checkBtn();
});
